import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "./Button";
import Modal from "react-bootstrap/Modal";
import { Image } from "react-bootstrap";
import application_received from "../images/application_received.svg";
import { useForm } from "react-hook-form";
import bitrixWebhook from "../ApiUrls/bitrixApiUrls";
import { telenorCmsAuth, telenorCmsSMS } from "../ApiUrls/telenorApiUrls";
import { creditPerPhoneNo } from "../creditPerDetails";

function FeedBackModal(props) {
  const { full_name } = props;
  return (
    <Modal
      {...props}
      full_name={full_name}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="react-bootstrap-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="seller-form__dialog-wrapper">
          <Image
            src={application_received}
            className="seller-form__dialog-image"
          />
        </div>
        <p className="seller-form__dialog-text">
          {`Hi, ${full_name}, CreditPer ke saath register kernay ka shukriya, qiston per products bechnay k liye humaray customer support department say raabta karein.`}
        </p>
        <p className="seller-form__dialog-text">
          {`Customer Support Department Phone Number: 051-8302737`}
        </p>
      </Modal.Body>
    </Modal>
  );
}

const SellerForm = ({ getTranslatedText }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  useEffect(() => {}, [reset]);

  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const formSubmissionEvent = () => {
    window.fbq("track", "Seller Form Submission");
    window.gtag("event", "Seller Form Submission", {
      event_category: "Button",
      event_label: "Buttons",
    });
  };

  const onSubmit = async (e) => {
    if (
      !full_name ||
      !phone ||
      !email ||
      !businessName ||
      !businessAddress ||
      !city ||
      !message
    )
      return;
    const bitrix_response = await submit_bitrix_form(
      full_name,
      phone,
      email,
      city,
      message,
      businessName,
      businessAddress
    );

    const result = bitrix_response;

    if (result) {
      formSubmissionEvent();
    } else {
      return bitrix_response;
    }
  };

  const submit_bitrix_form = async () => {
    const response = await axios.all([
      axios.post(`${bitrixWebhook}/crm.lead.add.json`, {
        fields: {
          SOURCE_ID: "CreditPer Website",
          SOURCE_DESCRIPTION: "CreditPer Website",
          TITLE: `Website Lead Seller Form - ${full_name}`,
          NAME: full_name,
          UF_CRM_1640592773232: phone,
          UF_CRM_1640592707918: email,
          UF_CRM_1643711617931: businessName,
          UF_CRM_1643711645653: businessAddress,
          UF_CRM_1640592689207: city,
          UF_CRM_1643711710729: message,
          UF_CRM_1640591706756: "---",
          POST: "---",
        },
      }),
      axios.get(`${telenorCmsAuth}`),
    ]);

    setModalShow(true);
    const telenorAPIData = response[1].data;

    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(telenorAPIData, "text/xml");

    const sessionID =
      xmlDoc.getElementsByTagName("data")[0].childNodes[0].nodeValue;

    const replySMS = `Hi, ${full_name}, CreditPer ke saath register kernay ka shukriya, qiston per products khareednay ya baychnay k liye humaray customer support department say raabta karein. Phone no: ${creditPerPhoneNo}`;

    const mask = "CreditPer";

    axios.post(
      `${telenorCmsSMS}?session_id=${sessionID}&to=${phone}&text=${replySMS}&mask=${mask}`
    );

    return response;
  };

  return (
    <>
      <FeedBackModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        full_name={full_name}
      />
      <form className="seller-form">
        <h2>{getTranslatedText("SellerFrom Formheading")}</h2>
        <label className="seller-form__label">
          <input
            {...register("full_name", {
              required: true,
            })}
            placeholder="Full Name"
            className="seller-form__input"
            value={full_name}
            name="full_name"
            type="text"
            onChange={(e) => setFullName(e.target.value)}
          />
          <div className="seller-form__urdu-label">نام</div>
        </label>
        {errors?.full_name?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm NameVal")}
          </p>
        )}
        <label className="seller-form__label">
          <input
            {...register("phone", {
              required: true,
              pattern: /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
            })}
            placeholder="Phone No"
            className="seller-form__input"
            name="phone"
            value={phone}
            type="tel"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <div className="seller-form__urdu-label">فون نمبر</div>
        </label>
        {errors.phone?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm PhoneVal")}
          </p>
        )}
        {errors?.phone?.type === "pattern" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm ValidPhoneVal")}
          </p>
        )}
        <label className="seller-form__label">
          <input
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+$/,
            })}
            placeholder="Email"
            className="seller-form__input"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="seller-form__urdu-label">ای میل</div>
        </label>
        {errors?.email?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm EmailVal")}
          </p>
        )}
        {errors?.email?.type === "pattern" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm ValidEmailVal")}
          </p>
        )}
        <label className="seller-form__label">
          <input
            {...register("businessName", {
              required: true,
            })}
            placeholder="Business/Company Name"
            className="seller-form__input"
            name="businessName"
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          <div className="seller-form__urdu-label">کمپنی</div>
        </label>
        {errors.businessName?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm BussinessNameVal")}
          </p>
        )}
        <label className="seller-form__label">
          <input
            {...register("businessAddress", {
              required: true,
            })}
            placeholder="Business/Company Address"
            className="seller-form__input"
            name="businessAddress"
            type="text"
            value={businessAddress}
            onChange={(e) => setBusinessAddress(e.target.value)}
          />
          <div className="seller-form__urdu-label"> کمپنی &nbsp;پتہ</div>
        </label>
        {errors.businessName?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm BussinessAddressVal")}
          </p>
        )}
        <label className="seller-form__label">
          <input
            {...register("city", {
              required: true,
            })}
            placeholder="City"
            className="seller-form__input"
            name="city"
            type="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <div className="seller-form__urdu-label">شہر</div>
        </label>
        {errors.city?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm CityVal")}
          </p>
        )}
        <label className="seller-form__label">
          <textarea
            {...register("message", {
              required: true,
            })}
            placeholder="Message"
            className="seller-form__input"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="seller-form__urdu-label">پیغام</div>
        </label>
        {errors.city?.type === "required" && (
          <p className="seller-form__error">
            {getTranslatedText("SellerForm MessageVal")}
          </p>
        )}

        {isSubmitting && (
          <span className="spinner-border spinner-border-md mr-1"></span>
        )}
        <Button
          label={getTranslatedText("SellerForm Button")}
          onClick={handleSubmit(onSubmit)}
        />
      </form>
    </>
  );
};

export default SellerForm;
