import React from 'react';
import { Image } from 'react-bootstrap';
import { creditPerWhatsApp } from '../creditPerDetails';
import whatsappIcon from '../images/whatsapp-icon.svg';

const WhatsappChat = () => {
  const onWhatsAppButtonClick = () => {
    window.fbq('track', 'WhatsApp Chat Button');
    window.gtag('event', 'WhatsApp Chat Button', {
      event_category: 'Button',
      event_label: 'Buttons',
    });
  };
  return (
    <a
      onClick={onWhatsAppButtonClick}
      href={`https://wa.me/${creditPerWhatsApp}`}
      target='_blank'
      rel='noopener noreferrer'>
      <Image src={whatsappIcon} fluid={true} className='whatsapp-chat' />
    </a>
  );
};

export default WhatsappChat;
