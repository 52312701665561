import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import HeroSeller from "../../components/HeroSeller";
import Footer from "../../components/Footer";
import WhatsappChat from "../../components/WhatsappChat";
import Sellercontent from "../../components/SellerContent";
import { localStorageKeys } from "../../utility/localstorage";
import { getTranslatedText } from "../../utility/constant";

const SellerIndexPage = () => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <HeroSeller
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <Sellercontent
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default SellerIndexPage;
