import React from "react";
import { Container, Image } from "react-bootstrap";
import logo from "../images/Creditper-Logo.svg";
import { Link } from "react-scroll";
import phoneCall from "../images/phone-call.svg";
import SellerBackground from "../img/seller-background.jpg";
import { creditPerPhoneNo } from "../creditPerDetails";
import { localStorageKeys } from "../utility/localstorage";
import { staticWebAppTextTranslation } from "../utility/constant";

const HeroSeller = ({
  getTranslatedText,
  myCustomAppState,
  setMyCustomAppState,
}) => {
  const onPhoneCallButtonClick = () => {
    window.fbq("track", "Phone Call Button");
    window.gtag("event", "Phone Call Button", {
      event_category: "Button",
      event_label: "Buttons",
    });
  };

  const switchLanguage = () => {
    const switchingToLang = Object.keys(staticWebAppTextTranslation).find(
      (item) => item !== myCustomAppState?.language
    );
    localStorage.setItem(localStorageKeys.language, switchingToLang);
    setMyCustomAppState({ [localStorageKeys.language]: switchingToLang });
  };

  return (
    <section className="hero-seller">
      <Image src={SellerBackground} className="header-background-image" />
      <div className="header-mobile">
        <div class="btn-container">
          <label class="switch">
            <input
              type="checkbox"
              name="toogle-mobile"
              id="toogle-mobile"
              onClick={switchLanguage}
            />
            <label
              for="toogle-mobile"
              data-on="اردو"
              data-off="En"
              class="switch-inner"
            ></label>
          </label>
        </div>

        <Link
          className="call-content"
          href={`tel:${creditPerPhoneNo}`}
          onClick={onPhoneCallButtonClick}
        >
          <Image className="phone-icon" src={phoneCall} fluid={true} />
          <div>{creditPerPhoneNo}</div>
        </Link>

        <Image className="logo" src={logo} />
        <p className="logo-text">
          Powered by CreditFix Financial Services Limited
        </p>
      </div>
      <div className="title-wrapper">
        <h1 className="content">
          {getTranslatedText("Seller BannerHeading1")}
        </h1>
        <h1 className="content">
          {getTranslatedText("Seller BannerHeading2")}
        </h1>
      </div>
    </section>
  );
};

export default HeroSeller;
