import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import SellerForm from "../components/SellerForm";
import Line from "./Line";
import SellerSVG from "../images/seller-color.svg";

const Sellercontent = ({
  getTranslatedText,
  myCustomAppState,
  setMyCustomAppState,
}) => {
  return (
    <section className="seller-content">
      <Container>
        <Row>
          <Col className="seller-content__col">
            <Line type="large" />
            <h2 className="seller-content__heading">
              {getTranslatedText("SellerForm Header")}
            </h2>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6} xl={6} className="seller-content__col">
            <Image src={SellerSVG} />
          </Col>
          <Col lg={6} xl={6} className="seller-content__col">
            <SellerForm
              myCustomAppState={myCustomAppState}
              setMyCustomAppState={setMyCustomAppState}
              getTranslatedText={getTranslatedText}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Sellercontent;
